.cartoon-img-container {
    display: flex;
    justify-content: center;
}

.cartoon-img-container img {
        max-width: 100%;
}

/* media query for 820px */
@media (max-width: 820px) {
    .cartoon-img-container {
        
    }

    .cartoon-img-container img {
        max-width: 80%;
    }
}